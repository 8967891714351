// Enums.js
export const CategoryEnum = {
    NonUpsetTubing: 1,
    ExternalUpsetTubing: 2,
    IntegralTubing: 3,
    ButtressCasing: 4,
};
export const FileIdItemName = {
    Deal: 1,
    Product: 2,
    ExpensePurchase: 3,
    Sale: 4,
    SalePoAcknowledge: 5,
    Partner: 6,
    InspectionRequest: 7,
    TransportRequest: 8
};
export const FileTypeEnum = {
    PictureProduct: 1,
    Certificate: 2,
    MTR: 3,
    InspectionReport: 4,
    SupplierProposal: 5,
    SupplierOffer: 6,
    ExpensePurchase: 7,
    SaleCustomerPoFile: 8,
    InspectionCompanyOffreFile: 9,
    TransportCompanyOffreFile: 10,
};

export function getCategoryDescription(category) {
    switch (category) {
        case CategoryEnum.NonUpsetTubing:
            return "Non Upset Tubing";
        case CategoryEnum.ExternalUpsetTubing:
            return "External Upset Tubing";
        case CategoryEnum.IntegralTubing:
            return "Integral Tubing";
        case CategoryEnum.ButtressCasing:
            return "Buttress Casing";
        default:
            return "Unknown Category";
    }
}

export const DriftEnum = {
    Standard: 1,
    Special: 2,
};
export function getDriftDescription(drift) {
    switch (drift) {
        case DriftEnum.Standard:
            return "Standard";
        case DriftEnum.Special:
            return "Special";
        default:
            return "Unknown Drift";
    }
}

